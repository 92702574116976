import React from 'react';
import cn from 'classnames';
import ReactInputMask from 'react-input-mask';
import Error from '../../Error';
import { hasValue } from '../../../helpers/validations';
import styles from './InputMask.module.scss';

const InputMask = (props) => {
  const { label, placeholder, className, onChange, value, meta } = props;
  const placeholderCn = cn(styles.inputLabelText, {
    [styles.filledInputLabelText]: hasValue(value) || (meta && meta.active),
  });
  const inputClassNames = cn(styles.input, {
    [className]: Boolean(className),
    [styles.inputLabel]: label,
    [styles.inputLabelWithText]: value.length > 0 && label,
    [styles.error]: meta && meta.error && meta.touched,
    [styles.noLabel]: !label,
  });
  return (
    <div className={styles.container}>
      <ReactInputMask
        placeholder={placeholder}
        className={inputClassNames}
        mask="+7 (999) 999-99-99"
        onChange={onChange}
        value={value}
      />
      {label ? <div className={placeholderCn}>{label}</div> : null}
      <Error meta={props.meta} />
    </div>
  );
};

export default InputMask;
