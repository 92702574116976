import React from 'react';
import PropTypes from 'prop-types';
import NumericTextbox from 'react-numeric-textbox';
import isNumber from 'lodash/isNumber';
import cn from 'classnames';
import Error from '../../Error';
import styles from './NumericInput.module.scss';

const hasValue = (value) => {
  if (isNumber(value)) {
    return true;
  }

  return Boolean(value);
};

const NumericInput = (props) => {
  const { placeholder, meta, value, decimals = 2, format = '0.00', onChange, onBlur, onFocus } = props;

  const handleChange = (value) => {
    onChange(value);
  };

  const placeholderCn = cn(styles.inputLabelText, {
    [styles.filledInputLabelText]: hasValue(value) || (meta && meta.active),
  });

  return (
    <div className={styles.container}>
      {placeholder ? <div className={placeholderCn}>{placeholder}</div> : null}

      <NumericTextbox
        className={cn(styles.input, {
          [props.className]: !!props.className,
          [styles.error]: meta && meta.error && meta.touched,
        })}
        value={value === '' ? null : Number(value)}
        decimals={decimals}
        format={format}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <Error meta={meta} />
    </div>
  );
};

NumericInput.defaultProps = {
  value: '',
  className: '',
  meta: {},
  placeholder: null,
  integers: true,
};

NumericInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape(),
  integers: PropTypes.bool,
};

export default NumericInput;
