import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Error.module.scss';

const Error = ({ meta }) => {
  if (!meta) {
    return null;
  }
  if ((meta.error || meta.submitError) && meta.touched) {
    return (
      <div className={cn('field-error', styles.error)}>
        {/* <img src="/icons2/error-UI-kit.svg" alt="errorIcon"></img> */}
        <span>{meta.error || meta.submitError}</span>
      </div>
    );
  }
  return null;
};

Error.defaultProps = {
  meta: {},
};

Error.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    submitError: PropTypes.string,
  }),
};

export default Error;
