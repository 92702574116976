import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Error from '../../Error';
import styles from './Textarea.module.scss';
import { hasValue } from '../../../helpers/validations';

const Textarea = (props) => {
  const { value, meta, className, placeholder, label, lgPadding, ...rest } = props;
  const classNames = cn(styles.textarea, {
    [className]: !!className,
    [styles.error]: meta && (meta.error || meta.submitError) && meta.touched,
    [styles.lgPadding]: lgPadding,
  });

  const placeholderCn = cn(styles.inputLabelText, {
    [styles.filledInputLabelText]: hasValue(value) || (meta && meta.active),
  });

  return (
    <div className={styles.container}>
      <textarea {...rest} className={classNames} value={value} />
      {placeholder || label ? <div className={placeholderCn}>{placeholder || label}</div> : null}
      <Error meta={meta} />
    </div>
  );
};

Textarea.defaultProps = {
  meta: {},
  className: null,
};

Textarea.propTypes = {
  meta: PropTypes.shape(),
  className: PropTypes.string,
};

export default Textarea;
