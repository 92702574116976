import React from 'react';
import PropTypes from 'prop-types';
import ReactAsyncSelect from 'react-select/async';
import ReactAsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import cn from 'classnames';
import api from '../../../lib/api';
import Option from './Option';
import MultiValueContainer from './MultiValueContainer';
import MultiValueRemove from './MultiValueRemove';
import MultiValueLabel from './MultiValueLabel';
import ValueContainer from './ValueContainer';
import Error from '../../Error';
import styles from './AsyncSelect.module.scss';

const getSelectComponent = ({ isCreatable }) => {
  if (!isCreatable) {
    return ReactAsyncSelect;
  }

  return ReactAsyncCreatableSelect;
};

const AsyncSelect = (props) => {
  const { route, routeName, meta, optionsRenderer, isCreatable, defaultOptions, ...rest } = props;

  const loadOptions = async (value) => {
    const results = await api.get(route[routeName], {
      params: {
        search: value,
      },
    });

    if (optionsRenderer) {
      return optionsRenderer(results);
    }

    return results.data.data.map((item) => ({
      id: item.id,
      name: item.name,
      data: item,
    }));
  };

  const ReactSelectComponent = getSelectComponent({ isCreatable });

  const asyncOptions = {
    ...(isCreatable && {
      SelectComponent: Creatable,
    }),
  };

  return (
    <div
      className={cn(styles.container, 'async-select', {
        [styles.withError]: (meta.error || meta.submitError) && meta.touched,
      })}
    >
      <ReactSelectComponent
        classNamePrefix="async-select"
        components={{
          Option,
          MultiValueContainer,
          MultiValueLabel,
          ValueContainer,
          MultiValueRemove,
          IndicatorSeparator: null,
          ClearIndicator: null,
        }}
        defaultOptions={defaultOptions}
        loadOptions={props.loadOptions ? props.loadOptions : loadOptions}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        blurInputOnSelect={false}
        noOptionsMessage={() => (rest.value ? 'Нет результатов' : 'Введите запрос')}
        getNewOptionData={(value, label) => ({ id: value, name: label })}
        {...rest}
        {...asyncOptions}
      />
      <Error meta={meta} />
    </div>
  );
};

AsyncSelect.defaultProps = {
  routeName: 'root',
  withPhoto: false,
  meta: {},
  optionsRenderer: null,
  isCreatable: false,
  defaultOptions: false,
};

AsyncSelect.propTypes = {
  route: PropTypes.shape().isRequired,
  routeName: PropTypes.string,
  withPhoto: PropTypes.bool,
  meta: PropTypes.shape(),
  optionsRenderer: PropTypes.func,
  isCreatable: PropTypes.bool,
  defaultOptions: PropTypes.bool,
};

export default AsyncSelect;
