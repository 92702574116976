import React from 'react';
import PropTypes from 'prop-types';
import styles from './AsyncSelect.module.scss';

const MultiValueContainer = (props) => (
  <div {...props.innerProps} className={styles.multiValue}>
    {props.children}
  </div>
);

MultiValueContainer.propTypes = {
  children: PropTypes.string.isRequired,
  innerProps: PropTypes.shape().isRequired,
};

export default MultiValueContainer;
