import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import styles from './AsyncSelect.module.scss';

const Option = (props) => {
  const { children, innerProps, isFocused, data, selectProps } = props;

  const className = cn(styles.option, {
    [styles.focused]: isFocused,
  });

  return (
    <div className={className} {...omit(innerProps, ['onMouseMove', 'onMouseOver'])}>
      {selectProps.withPhoto && (
        <div className={styles.photo}>
          <img src={data.data.photo} />
          <div className={styles.status} />
        </div>
      )}
      {children}
    </div>
  );
};

Option.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  innerProps: PropTypes.shape().isRequired,
  selectProps: PropTypes.shape({
    withPhoto: PropTypes.bool,
  }).isRequired,
  data: PropTypes.shape().isRequired,
};

export default Option;
