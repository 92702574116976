import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../../../public/icons/remove.svg';
import styles from './AsyncSelect.module.scss';

const AsyncMultiValueRemove = (props) => (
  <div {...props.innerProps} className={styles.valueRemove}>
    <CloseIcon width={8} height={8} fill="white" />
  </div>
);

AsyncMultiValueRemove.propTypes = {
  innerProps: PropTypes.shape().isRequired,
};

export default AsyncMultiValueRemove;
