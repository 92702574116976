import trim from 'lodash/trim';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import IMAGE_TYPES from '../constants/IMAGE_TYPES';

const hasValue = (value) => {
  if (isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'number') {
    return true;
  }

  if (Number(value)) {
    return true;
  }

  return Boolean(value);
};

/* eslint-disable */
const validEmailRegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

const EMAIL_REGEX = /(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/i;

/* eslint-enable */

const required = () => (value) => {
  const currentValue = isBoolean(value) ? value : trim(value);

  if (currentValue) return undefined;

  return 'Это обязательное поле';
};

const requiredWithMessage = (message) => (value) => (value ? undefined : message);
const minValue = (min, t) => (value) =>
  value.length >= min ? undefined : t('forms:min_characters', { min });
const maxValue = (max) => (value) =>
  (value ? value.length : 0) <= max ? undefined : `Максимум ${max} символов`;
const mustBeNumber = (t) => (value) =>
  value && Number.isNaN(Number(value)) ? t('forms:must_number') : undefined;
const composeValidators =
  (...validators) =>
  (value, formValues, meta) =>
    validators.reduce((error, validator) => error || validator(value, formValues, meta), undefined);
const minNumberValue = (min) => (value) =>
  value >= min || value === undefined ? undefined : `Минимальное значение ${min}`;
const maxNumberValue = (max) => (value) =>
  value <= max || value === undefined ? undefined : `Максимальное значение ${max}`;
const validRusPhoneRegExp = new RegExp(
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
);

export const phoneValidation = async (value) => {
  if (value) {
    const { parsePhoneNumberFromString } = await import('libphonenumber-js');
    const phoneNumber = parsePhoneNumberFromString(value, 'RU');
    if (phoneNumber && phoneNumber.isValid()) {
      if (!validRusPhoneRegExp.test(phoneNumber.number)) {
        return 'Доступны только номера РФ';
      }

      return undefined;
    } else {
      return 'Номер телефона введён некорректно';
    }
  }

  return 'Номер телефона введён некорректно';
};
const validEmail = (value) => {
  if (!value) return undefined;

  return validEmailRegExp.test(value) ? undefined : 'E-mail адрес введён некорректно';
};

const imageValidation = ({
  maxSize = 5,
  file,
  target,
  imageTypes = [IMAGE_TYPES.PNG, IMAGE_TYPES.JPEG],
}) =>
  new Promise((resolve, reject) => {
    const sizeInMb = file.size / 1024 / 1024;

    if (maxSize && sizeInMb > maxSize) {
      target.value = '';
      reject(new Error(`File too large. Max size is ${maxSize}MB`));
    }

    if (!imageTypes.includes(file.type)) {
      target.value = '';
      reject(
        new Error(
          `You can upload only ${imageTypes
            .map((type) => type.replace('image/', ''))
            .join(', ')} images`,
        ),
      );

      resolve();
    }
  });

const validURL = (t) => (value) => {
  if (!value) return undefined;
  const pattern = new RegExp(
    // eslint-disable-next-line
    '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
    'i',
  );

  return pattern.test(value) ? undefined : t('forms:not_valid_url');
};

const mustBePositiveNumber = (value) =>
  typeof value === 'undefined' || value >= 0
    ? undefined
    : 'Значение не может быть  отрицательным .';

const cannotBeEqualZero = (value) => {
  if (typeof value !== 'undefined') return undefined;

  return value == 0 ? 'Значение не может быть равен нулю.' : undefined;
};

export {
  cannotBeEqualZero,
  mustBePositiveNumber,
  required,
  requiredWithMessage,
  minValue,
  maxValue,
  mustBeNumber,
  composeValidators,
  validEmail,
  imageValidation,
  minNumberValue,
  maxNumberValue,
  hasValue,
  validURL,
};
