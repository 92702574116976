import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Error from '../Error';
// import InputLabel from '../FormControls/InputLabel';
import { hasValue } from '../../helpers/validations';
import styles from './Input.module.scss';
import Button from '../Button';

const Input = (props) => {
  const {
    simplePlaceholder,
    placeholder,

    className,
    id,
    meta,
    price = false,
    valueDelete,
    label,
    search,
    onFocus,
    whiteBack,
    onBlur,
    onClick,
    type = 'text',
    value,
    disabled,
    onChange,
    length,
  } = props;
  const inputClassNames = cn(styles.input, {
    [className]: Boolean(className),
    [styles.inputSearch]: search,
    [styles.whiteBack]: whiteBack,
    [styles.inputLabel]: label,
    [styles.inputLabelWithText]: value.length > 0 && label,
    [styles.error]: meta && meta.error && meta.touched,
    [styles.valueDelete]: value.length > 0 && valueDelete,
    [styles.price]: price && ((meta && meta.active) || hasValue(value)),
  });
  const placeholderCn = cn(styles.inputLabelText, {
    [styles.filledInputLabelText]: hasValue(value) || (meta && meta.active),
  });

  return (
    <div className={styles.container}>
      <input
        maxLength={length}
        type={type}
        className={inputClassNames}
        id={id}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        placeholder={simplePlaceholder}
      />
      {placeholder || label ? <div className={placeholderCn}>{placeholder || label}</div> : null}

      {value.length > 0 && valueDelete ? (
        <Button className={styles.closeButton} variant="transparent" onClick={onClick}>
          <img src="/icons2/close-UI_kit.svg" alt="close"></img>
        </Button>
      ) : null}

      <Error meta={meta} />
    </div>
  );
};

Input.defaultProps = {
  price: false,
  placeholder: null,
  className: null,
  id: null,
  type: 'text',
  meta: null,
};

Input.propTypes = {
  price: PropTypes.bool,
  meta: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
